export default {
  "title": {
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Password"])},
    "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
  },
  "projectShow": {
    "tab": {
      "formAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Access"])}
    }
  },
  "formHead": {
    "tab": {
      "versions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versions"])},
      "publicAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Access"])}
    },
    "draftNav": {
      "tab": {
        "testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testing"])}
      }
    }
  },
  "systemHome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Management"])},
    "tab": {
      "audits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Audit Logs"])},
      "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage Reporting"])}
    }
  },
  "role": {
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Manager"])},
    "viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Viewer"])},
    "formfill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Collector"])}
  },
  "formState": {
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "unpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not published yet"])}
  },
  "reviewState": {
    "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
    "null": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("reviewState.received", undefined, _type)])},
    "hasIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has issues"])},
    "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited"])},
    "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])}
  },
  "audit": {
    "category": {
      "nonverbose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(All Actions)"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web User Actions"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Actions"])},
      "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Actions"])},
      "field_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App User Actions"])},
      "public_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Access Link Actions"])},
      "dataset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity List Actions"])},
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Configuration Actions"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Upgrade"])},
      "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Operation"])}
    },
    "action": {
      "default": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Details"])},
        "session_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
      },
      "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Usage"])},
      "blobs": {
        "s3_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Files to Storage"])},
        "s3_failed_to_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark Files for Re-Upload to Storage"])}
      },
      "config": {
        "set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set"])}
      },
      "dataset": {
        "create": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.create", undefined, _type)])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
        "update_publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])}
      },
      "field_key": {
        "create": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.create", undefined, _type)])},
        "assignment_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give Access"])},
        "assignment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Access"])},
        "session_end": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.session_end", undefined, _type)])},
        "delete": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.delete", undefined, _type)])}
      },
      "form": {
        "create": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.create", undefined, _type)])},
        "update": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.update", undefined, _type)])},
        "update_draft_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create or Update Draft"])},
        "update_draft_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace Draft"])},
        "update_publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Draft"])},
        "update_draft_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandon Draft"])},
        "attachment_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Attachments"])},
        "submission_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Submissions"])},
        "delete": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.delete", undefined, _type)])},
        "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undelete"])},
        "purge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purge"])}
      },
      "project": {
        "create": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.create", undefined, _type)])},
        "update": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.update", undefined, _type)])},
        "delete": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.delete", undefined, _type)])}
      },
      "public_link": {
        "create": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.create", undefined, _type)])},
        "assignment_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give Access"])},
        "assignment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Access"])},
        "session_end": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.session_end", undefined, _type)])},
        "delete": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.delete", undefined, _type)])}
      },
      "submission": {
        "purge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purge Submissions"])}
      },
      "upgrade": {
        "process_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Form"])},
        "process_form_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Form Draft"])},
        "process_form_entities_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flag Form for Upgrade"])}
      },
      "user": {
        "create": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.create", undefined, _type)])},
        "update": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("audit.action.default.update", undefined, _type)])},
        "assignment_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Role"])},
        "assignment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke Role"])},
        "session_create": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("action.logIn", undefined, _type)])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retire"])}
      }
    }
  },
  "action": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "createSubmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "looksGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks good, proceed"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "neverMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never mind, cancel"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "noCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancel"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "reviewParallelUpdates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review parallel updates"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saveSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save settings"])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "yesProceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, proceed"])},
    "showPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
    "hidePreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide preview"])},
    "newPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Preview"])},
    "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand"])},
    "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undelete"])}
  },
  "field": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a comment…"])},
    "dateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date range"])},
    "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
    "passphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passphrase"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passwordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password (confirm)"])},
    "reviewState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review State"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
  },
  "header": {
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated by"])},
    "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "instanceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instance ID"])},
    "lastSubmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Submission"])},
    "recentActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Activity"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "listName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Name"])},
    "submitterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted by"])},
    "submissionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted at"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "entities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Entities"])},
    "lastEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Entity"])}
  },
  "plural": {
    "appUser": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["App User"]), _normalize(["App Users"])])},
    "form": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Form"]), _normalize(["Forms"])])},
    "project": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Project"]), _normalize(["Projects"])])},
    "submission": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Submission"]), _normalize(["Submissions"])])},
    "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["User"]), _normalize(["Users"])])},
    "webUser": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Web User"]), _normalize(["Web Users"])])}
  },
  "count": {
    "appUser": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " App User"]), _normalize([_interpolate(_named("count")), " App Users"])])},
    "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Form"]), _normalize([_interpolate(_named("count")), " Forms"])])},
    "submission": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Submission"]), _normalize([_interpolate(_named("count")), " Submissions"])])},
    "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " warning"]), _normalize([_interpolate(_named("count")), " warnings"])])}
  },
  "resource": {
    "appUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App User"])},
    "appUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Users"])},
    "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Configuration"])},
    "entityList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity List"])},
    "entityLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Lists"])},
    "entities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entities"])},
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
    "forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forms"])},
    "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
    "formAttachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Attachments"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property"])},
    "publicLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Access Link"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "webUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web User"])},
    "webUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Users"])},
    "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
    "submissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submissions"])},
    "projectRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Roles"])},
    "formPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Preview"])}
  },
  "alert": {
    "passwordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input a password at least 10 characters long."])},
    "updateEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Entity has been updated!"])},
    "updateReviewState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review State saved!"])},
    "versionChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server has been updated. Please refresh the page to avoid unpredictable behavior."])},
    "submissionDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Submission has been deleted."])},
    "submissionRestored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Submission has been undeleted."])}
  },
  "moreInfo": {
    "clickHere": {
      "full": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For more information, ", _interpolate(_named("clickHere")), "."])},
      "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click here"])}
    },
    "helpArticle": {
      "full": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For more information, please see ", _interpolate(_named("helpArticle")), "."])},
      "helpArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this help article"])}
    },
    "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more."])}
  },
  "analytics": {
    "alwaysImprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are always trying to improve ODK Central."])},
    "needFeedback": {
      "full": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To do this, we need ", _interpolate(_named("your")), " feedback, so that we can understand how you are using Central, and how it could be better for you."])},
      "your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your"])}
    }
  },
  "submission": {
    "missingAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing Attachment"])},
    "action": {
      "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Edit (", _interpolate(_named("count")), ")"])}
    },
    "editDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot edit encrypted Submissions."])},
    "binaryLinkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File was submitted. Click to download."])},
    "encryptionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data preview is not available due to encryption."])},
    "noSubmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(none)"])},
    "emptyTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no Submissions yet."])}
  },
  "entity": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
    "entityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Label"])},
    "entityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity ID"])},
    "noProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Form only sets the “label”."])},
    "noEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(none)"])},
    "conflictsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conflict"]), _normalize([_interpolate(_named("count")), " conflicts"])])}
  },
  "common": {
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "anybody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Anybody)"])},
    "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you wish to proceed?"])},
    "basicInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Information"])},
    "conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflict"])},
    "conflicts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflicts"])},
    "currentDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Current Draft"])},
    "dangerZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danger Zone"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docs"])},
    "emptyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(empty)"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading…"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
    "noUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone."])},
    "rightNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Now"])},
    "rowNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
    "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "tab": {
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "totalSubmissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Submissions"])},
    "propertiesCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("inform")), " of ", _interpolate(_named("count")), " property"]), _normalize([_interpolate(_named("inform")), " of ", _interpolate(_named("count")), " properties"])])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New!"])},
    "totalEntities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Entities"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
    "versionShort": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["v", _interpolate(_named("version"))])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])}
  },
  "mixin": {
    "request": {
      "alert": {
        "fileSize": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The file “", _interpolate(_named("name")), "” that you are trying to upload is larger than the 100 MB limit."])},
        "entityTooLarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data that you are trying to upload is too large."])}
      }
    }
  },
  "requestData": {
    "project": {
      "nameWithArchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (archived)"])}
    }
  },
  "util": {
    "csv": {
      "readError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There was a problem reading your file: ", _interpolate(_named("message"))])},
      "invalidCSV": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The file “", _interpolate(_named("name")), "” is not a valid .csv file. It cannot be read."])},
      "rowError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There is a problem on row ", _interpolate(_named("row")), " of the file: ", _interpolate(_named("message"))])},
      "invalidQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A quoted field is invalid. Check the row to see if there are any unusual values."])},
      "emptyRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The row is empty."])},
      "dataWithoutHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Expected ", _interpolate(_named("expected")), " column, but found ", _interpolate(_named("actual")), "."]), _normalize(["Expected ", _interpolate(_named("expected")), " columns, but found ", _interpolate(_named("actual")), "."])])}
    },
    "request": {
      "noRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong: there was no request."])},
      "noResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong: there was no response to your request."])},
      "errorNotProblem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Something went wrong: error code ", _interpolate(_named("status")), "."])},
      "problem": {
        "404_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The resource you are looking for cannot be found. The resource may have been deleted."])}
      }
    },
    "session": {
      "alert": {
        "expiresSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session will expire in 2 minutes, and you be automatically logged out."])},
        "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session has expired. To continue working, please log in again."])},
        "logoutError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There was a problem, and you were not fully logged out. Please refresh the page and try logging out again. ", _interpolate(_named("message"))])}
      }
    }
  },
  "router": {
    "unsavedChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave this page? Your changes might not be saved."])}
  }
}